span.slogan {
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 0;
}
span.slogan-operator{
    font-size: x-large;
    font-weight: bold;
    font-style: italic;
    color:brown;
}

img.slogan-picture {
    border-radius: 4px;
    width: 280px !important;
    height: 280px !important;
}

.container {
    display: flex;
    align-items:last baseline;
  }
  
.element {
    flex: 1;
}

