
#wrapper {
    -moz-transition: opacity 0.45s ease;
    -webkit-transition: opacity 0.45s ease;
    -ms-transition: opacity 0.45s ease;
    transition: opacity 0.45s ease;
    opacity: 1;
}

#menu {
    -moz-transform: translateX(22em);
    -webkit-transform: translateX(22em);
    -ms-transform: translateX(22em);
    transform: translateX(22em);
    -moz-transition: -moz-transform 0.45s ease, visibility 0.45s;
    -webkit-transition: -webkit-transform 0.45s ease, visibility 0.45s;
    -ms-transition: -ms-transform 0.45s ease, visibility 0.45s;
    transition: transform 0.45s ease, visibility 0.45s;
    position: fixed;
    top: 0;
    right: 0;
    width: 22em;
    max-width: 80%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    background: #585858;
    color: #ffffff;
    cursor: default;
    visibility: hidden;
    z-index: 10002;
}

    #menu > .inner {
        -moz-transition: opacity 0.45s ease;
        -webkit-transition: opacity 0.45s ease;
        -ms-transition: opacity 0.45s ease;
        transition: opacity 0.45s ease;
        -webkit-overflow-scrolling: touch;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 2.75em;
        opacity: 0;
        overflow-y: auto;
    }

        #menu > .inner > ul {
            list-style: none;
            margin: 0 0 1em 0;
            padding: 0;
        }

            #menu > .inner > ul > li {
                padding: 0;
                border-top: solid 1px rgba(255, 255, 255, 0.15);
            }

                #menu > .inner > ul > li a {
                    display: block;
                    padding: 1em 0;
                    line-height: 1.5;
                    border: 0;
                    color: inherit;
                }

                #menu > .inner > ul > li:first-child {
                    border-top: 0;
                    margin-top: -1em;
                }

    #menu > .close {
        -moz-transition: opacity 0.45s ease, -moz-transform 0.45s ease;
        -webkit-transition: opacity 0.45s ease, -webkit-transform 0.45s ease;
        -ms-transition: opacity 0.45s ease, -ms-transform 0.45s ease;
        transition: opacity 0.45s ease, transform 0.45s ease;
        -moz-transform: scale(0.25) rotate(180deg);
        -webkit-transform: scale(0.25) rotate(180deg);
        -ms-transform: scale(0.25) rotate(180deg);
        transform: scale(0.25) rotate(180deg);
        -webkit-tap-highlight-color: transparent;
        display: block;
        position: absolute;
        top: 2em;
        left: -6em;
        width: 6em;
        text-indent: 6em;
        height: 3em;
        border: 0;
        font-size: 1em;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        white-space: nowrap;
    }

        #menu > .close:before, #menu > .close:after {
            -moz-transition: opacity 0.2s ease;
            -webkit-transition: opacity 0.2s ease;
            -ms-transition: opacity 0.2s ease;
            transition: opacity 0.2s ease;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 2em 2em;
        }

        #menu > .close:before {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cstyle%3Eline %7B stroke-width: 8px%3B stroke: %23f2849e%3B %7D%3C/style%3E%3Cline x1='15' y1='15' x2='85' y2='85' /%3E%3Cline x1='85' y1='15' x2='15' y2='85' /%3E%3C/svg%3E");
            opacity: 0;
        }

        #menu > .close:after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cstyle%3Eline %7B stroke-width: 8px%3B stroke: %23585858%3B %7D%3C/style%3E%3Cline x1='15' y1='15' x2='85' y2='85' /%3E%3Cline x1='85' y1='15' x2='15' y2='85' /%3E%3C/svg%3E");
            opacity: 1;
        }

        #menu > .close:hover:before {
            opacity: 1;
        }

        #menu > .close:hover:after {
            opacity: 0;
        }

    @media screen and (max-width: 736px) {

        #menu {
            -moz-transform: translateX(16.5em);
            -webkit-transform: translateX(16.5em);
            -ms-transform: translateX(16.5em);
            transform: translateX(16.5em);
            width: 16.5em;
        }

            #menu > .inner {
                padding: 2.75em 1.5em;
            }

            #menu > .close {
                top: 0.5em;
                left: -4.25em;
                width: 4.25em;
                text-indent: 4.25em;
            }

                #menu > .close:before, #menu > .close:after {
                    background-size: 1.5em 1.5em;
                }

    }

body.is-menu-visible #wrapper {
    pointer-events: none;
    cursor: default;
    opacity: 0.25;
}

body.is-menu-visible #menu {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
}

    body.is-menu-visible #menu > * {
        opacity: 1;
    }

    body.is-menu-visible #menu .close {
        -moz-transform: scale(1.0) rotate(0deg);
        -webkit-transform: scale(1.0) rotate(0deg);
        -ms-transform: scale(1.0) rotate(0deg);
        transform: scale(1.0) rotate(0deg);
        opacity: 1;
    }